import React, { useState } from "react";
import { addDoc, collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

const WaitlistForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    apartmentName: "",
    lookingForSublease: "",
  });

  const [waitlistPosition, setWaitlistPosition] = useState(null); 
  const [formSubmitted, setFormSubmitted] = useState(false);


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  const getWaitlistPosition = async () => {
    try {
     
      const counterRef = doc(db, "counters", "waitlistCounter");
      const counterSnapshot = await getDoc(counterRef);

      let newPosition;
      if (counterSnapshot.exists()) {
    
        newPosition = counterSnapshot.data().count + 1;
        await updateDoc(counterRef, { count: newPosition });
      } else {
      
        newPosition = 1;
        await setDoc(counterRef, { count: newPosition });
      }

      return newPosition;
    } catch (error) {
      console.error("Error fetching waitlist position: ", error);
      return null;
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.values(formData).some((field) => field === "")) {
      alert("Please fill in all required fields.");
      return;
    }

    try {
     
      await addDoc(collection(db, "waitlist"), formData);


      const position = await getWaitlistPosition();
      setWaitlistPosition(position);
      setFormSubmitted(true);

      
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        apartmentName: "",
        lookingForSublease: "",
      });
    } catch (error) {
      console.error("Error submitting form: ", error);
      alert("Error submitting form.");
    }
  };

  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto max-w-lg p-8 bg-white shadow-lg rounded-lg border border-black">
        <h2 className="text-4xl font-bold mb-4 text-center text-black">Join Our Waitlist</h2>
        {formSubmitted && waitlistPosition && (
          <p className="text-center text-lg mb-6 text-black">
            Congratulations! You are number <strong>{waitlistPosition}</strong> on the waitlist.
          </p>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* First Name */}
          <div>
            <label htmlFor="firstName" className="block text-black">
              First Name *
            </label>
            <input
              id="firstName"
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="Enter your first name"
              className="w-full p-2 border border-gray-400 rounded-lg"
              required
            />
          </div>
          {/* Last Name */}
          <div>
            <label htmlFor="lastName" className="block text-black">
              Last Name *
            </label>
            <input
              id="lastName"
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Enter your last name"
              className="w-full p-2 border border-gray-400 rounded-lg"
              required
            />
          </div>
          {/* Email */}
          <div>
            <label htmlFor="email" className="block text-black">
              Email *
            </label>
            <input
              id="email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Enter your email"
              className="w-full p-2 border border-gray-400 rounded-lg"
              required
            />
          </div>
          {/* Phone Number */}
          <div>
            <label htmlFor="phone" className="block text-black">
              Phone Number *
            </label>
            <input
              id="phone"
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Enter your phone number"
              className="w-full p-2 border border-gray-400 rounded-lg"
              required
            />
          </div>
          {/* Apartment Name */}
          <div>
            <label htmlFor="apartmentName" className="block text-black">
              Apartment Name *
            </label>
            <input
              id="apartmentName"
              type="text"
              name="apartmentName"
              value={formData.apartmentName}
              onChange={handleChange}
              placeholder="Enter your apartment name"
              className="w-full p-2 border border-gray-400 rounded-lg"
              required
            />
          </div>
          {/* Looking for Sublease */}
          <div>
            <label htmlFor="lookingForSublease" className="block text-black">
              Are you looking for a sublease your apartment? *
            </label>
            <select
              id="lookingForSublease"
              name="lookingForSublease"
              value={formData.lookingForSublease}
              onChange={handleChange}
              className="w-full p-2 border border-gray-400 rounded-lg"
              required
            >
              <option value="">Select an option</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          {/* Submit Button */}
          <button
            type="submit"
            className="w-full p-2 bg-black text-white font-bold rounded-lg hover:bg-gray-800 transition-all"
          >
            Submit
          </button>
        </form>
      </div>
    </section>
  );
};

export default WaitlistForm;
