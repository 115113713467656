import React from "react";
import Navbar from "./components/Navbar";
import OurStory from "./components/OurStory";
import Team from "./components/Team";
import WaitlistForm from "./components/WaitlistForm";
import HomePage from "./components/HomePage"
function App() {
  return (
    <div>
      <Navbar />
      <HomePage />
    </div>
  );
}

export default App;
