import React from "react";
import skylineImage from "../assets/skyline.jpeg";
import teamImage from "../assets/umn.jpeg"; 
import OurStory from "./OurStory";
import Team from "./Team";
import WaitlistForm from "./WaitlistForm";

const HomePage = () => {
  return (
    <main className="min-h-screen bg-gray-100">
     
      <section
        className="relative w-full h-[400px] flex items-center justify-center text-white"
        style={{
          backgroundImage: `url(${skylineImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <h1 className="relative text-5xl font-bold z-10">Make Subleasing Easier with Perch</h1>
      </section>


      <div className="container mx-auto px-4 py-12 grid grid-cols-1 lg:grid-cols-3 gap-8">
      
        <section className="lg:col-span-2 space-y-8 text-black">
          <div>
            <h2 className="text-3xl font-semibold">Our Mission</h2>
            <p className="text-lg leading-relaxed mt-2">
              At Perch, we aim to eliminate the stress of finding or listing subleases. Our platform makes subleasing accessible, removing negotiation barriers and simplifying decisions with AI-powered suggestions.
            </p>
          </div>

          <div>
            <h2 className="text-3xl font-semibold">Our Story</h2>
            <p className="text-lg leading-relaxed mt-2">
              As current college students living approximately 25 minutes away from the University of Minnesota, we experienced a peculiar three-month gap where we were not residing in our campus apartments but were still responsible for paying rent. This resulted in an unnecessary $3,000 expense over the summer until our next lease began. To offset this cost, we attempted to sublease our units for the summer. However, the existing platforms made it challenging to find genuinely interested parties, communicate effectively, and finalize agreements promptly. Through friends and intensive negotiation on pricing, we managed to find subleases to take over our leases, saving us $3,000 and providing local interns with summer housing.
            </p>
            <p className="text-lg leading-relaxed mt-4">
              The stress and barriers we encountered during this process inspired our team to create a better, quicker, and simpler way to sublease apartments. This led to the development of Perch Housing, an AI-driven platform designed to streamline the subleasing process for both Subleasers and Sublessees.
            </p>
          </div>
        </section>

     
        <aside className="lg:col-span-1 space-y-8">
          <div className="flex justify-center items-start">
            <img
              src={teamImage}
              alt="UMN Image"
              className="w-full h-auto max-h-[400px] object-cover rounded-lg shadow-lg"
            />
          </div>

          <div>
            <h2 className="text-3xl font-semibold">How It Works</h2>
            <h3 className="text-xl font-semibold mt-4">For Subleasers</h3>
            <p className="text-lg leading-relaxed">
              Enter your preferences, and Perch will match you with top options, reducing decision fatigue by presenting only the most compatible listings.
            </p>
            <h3 className="text-xl font-semibold mt-4">For Sublessees</h3>
            <p className="text-lg leading-relaxed">
              Create a listing that matches your ideal tenant criteria. Our AI instantly connects you with suitable subleasers, streamlining your search.
            </p>
          </div>
        </aside>
      </div>


      <section className="py-12 bg-white">
        <Team /> 
      </section>


      <section className="py-12 bg-white border-t border-gray-300">
        <div className="container mx-auto max-w-lg p-8 bg-white shadow-lg rounded-lg border border-black">
          <WaitlistForm />
        </div>
      </section>
    </main>
  );
};

export default HomePage;
