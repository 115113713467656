// src/components/Navbar.js
import React from "react";
import logo from "../assets/perkslogo.jpeg";

const Navbar = () => {
  const scrollToSection = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <nav style={{ background: "linear-gradient(to right, white, white)" }}>
      <div className="container mx-auto p-4 flex justify-between items-center">
        <div
          className="flex items-center space-x-2 cursor-pointer"
          onClick={scrollToTop}
        >
          <img src={logo} alt="Perch Logo" className="w-9 h-9" />
          <h1 className="text-2xl font-bold text-black">Perch</h1>
        </div>
        <ul className="flex space-x-8">
          <li>
            <button
              onClick={() => scrollToSection("story")}
              className="text-black hover:text-gray-300"
            >
              Our Story
            </button>
          </li>
          <li>
            <button
              onClick={() => scrollToSection("team")}
              className="text-black hover:text-gray-300"
            >
              Team
            </button>
          </li>
          <li>
            <button
              onClick={() => scrollToSection("waitlist")}
              className="text-black hover:text-gray-300"
            >
              Waitlist
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
