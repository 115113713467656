import React from "react";
import shrenikImg from "../assets/shrenik.jpeg";
import sevaanImg from "../assets/sevaan.jpeg";
import madhanImg from "../assets/madhan.jpeg";
import sumanthImg from "../assets/sumanth.jpeg";

const Team = () => {
  const teamMembers = [
    { name: "Shrenik Mamidala", role: "CEO", image: shrenikImg },
    { name: "Sevaan Puri", role: "COO", image: sevaanImg },
    { name: "Madhan Mohan", role: "CTO", image: madhanImg },
    { name: "Sumanth Kota", role: "Founding Engineer", image: sumanthImg },
  ];

  return (
    <div className="flex flex-col items-center space-y-12 py-12 bg-white">
      <h2 className="text-4xl font-bold text-center">Our Team</h2>
      <div className="flex flex-wrap justify-center items-center gap-12">
        {teamMembers.map((member) => (
          <div
            key={member.name}
            className="text-center bg-white p-6 rounded-lg border border-black shadow-lg w-64"
          >
            <img
              src={member.image}
              alt={member.name}
              className="w-32 h-32 rounded-full object-cover mx-auto border-2 border-black"
            />
            <h3 className="text-xl mt-4">{member.name}</h3>
            <p>{member.role}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
